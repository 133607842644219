import React, {Fragment} from 'react';
import Link from  'gatsby-link';

const PageNotFound = () => {

  return (
    <Fragment>
      <div className="px-12 max-w-lg m-auto md:max-w-lg md:px-6 lg:max-w-lg lg:px-8">
        <div className='text-center md:text-left mt-6 mb-16'>
          <h1 className='text-4xl font-bold uppercase'>Page Not Found</h1>
        </div>
        
        <div className="grid">
          <p className="mb-4">We couldn't find the page you were looking for. </p>
          <Link to='/' className='hover:text-red-500'>Connections</Link>
          <Link to='/explore' className='hover:text-red-500'>Explore Topics</Link>
        </div>    
      </div>
      </Fragment>
  );
};

export default PageNotFound;